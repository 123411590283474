import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme
} from '@material-ui/core';
import { useErrorHandler } from "react-error-boundary";
import cogoToast from 'cogo-toast';
import { BounceLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as TipperService from 'services/tipper.service';

const addInitData = {
  id: null,
  tipper_code: null
}

const useStyles = makeStyles(theme => ({
  container: {
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  }
}));

const TestPages = () => {
  const classes = useStyles();
  const theme = useTheme();

  const handleError = useErrorHandler();

  useEffect(() => {
    try {
      // TipperService.getTippers().then((result)=>{
      //   const resultpp = {

      //   }
      //   const customResult = resultpp.data.map((item, index)=>(
      //     {
      //       id: item.id,
      //       index: index + 1,
      //       tipper_code: item.code,
      //     }
      //   ))
      // });
      const resultpp = {

      }
      const customResult = resultpp.data.map((item, index)=>(
        {
          id: item.id,
          index: index + 1,
          tipper_code: item.code,
        }
      ))
    } catch (err) {
      // console.log('yyyy', err);
      handleError(err)
    }

    // throw new Error('xx')
    // handleError({xvv: 'yyyy'})
  }, []);


  return (
    <Fragment>
      <Grid
        className={classes.container}
        container
      >
        <div>wefwefewf</div>
      </Grid>
    </Fragment>
  );
};

export default TestPages;
