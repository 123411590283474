import {
  UPDATE_COLUMNS,
  UPDATE_ODDS,
  UPDATE_AMOUNT,
  UPDATE_LEAGUES,
  UPDATE_LOADED,
  UPDATE_PARTNER,
  UPDATE_CLICKS_E_D,
  UPDATE_PENDING_ACTION_GROUP
} from './types'
const companyName = process.env.REACT_APP_ENVIORNMENT

const initialState = {
  odds: 'US', // Decimal
  amount: 'total', // ourSide, partnerSide
  company: companyName, // runItUp, maxEmpire, theBettingSyndicate
  clicks: [
    {
      id: '1',
      name: "id",
      checked: true,
    },
    {
      id: '2',
      name: "league_name",
      checked: true,
    },
    {
      id: '3',
      name: "event_name",
      checked: true,
    },
    {
      id: '4',
      name: "date",
      checked: true,
    },
    {
      id: '5',
      name: "bet_description",
      checked: true,
    },
    {
      id: '16',
      name: "short_description",
      checked: true,
    },
    {
      id: '6',
      name: "line",
      checked: true,
    },
    {
      id: '7',
      name: "risk",
      checked: true,
    },
    {
      id: '8',
      name: "to_win",
      checked: true,
    },
    {
      id: '9',
      name: 'currency',
      checked: true
    },
    {
      id: '10',
      name: "profit",
      checked: true,
    },
    {
      id: '20',
      name: "closing_home",
      checked: true,
    },
    {
      id: '21',
      name: "closing_away",
      checked: true,
    },
    {
      id: '22',
      name: "closing_handicap",
      checked: true,
    },
    {
      id: '11',
      name: "bet_time",
      checked: true,
    },
    {
      id: '12',
      name: "book",
      checked: true,
    },
    {
      id: '13',
      name: "share_type",
      checked: true,
    },
    {
      id: '14',
      name: "tips_for",
      checked: true,
    },
    {
      id: '15',
      name: "tips_against",
      checked: true,
    },
    {
      id: '17',
      name: "logged_by",
      checked: true,
    },
    {
      id: '18',
      name: "log_time",
      checked: true,
    },
    {
      id: '19',
      name: "status",
      checked: true,
    },
  ],
  leagues: [],
  loaded: false,
  clicksED: false, // edit popup of the user
  partner: [],
  pendingActionGroup: [
    { columnName: 'partner' },
    { columnName: 'sport' },
    { columnName: 'league_name' },
    { columnName: 'event_name' }
  ] // partner, sport, league_name, event_name, date, share_type, bet_category, bet_name, book
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ODDS:
      return {
        ...state,
        odds: action.payload
      }
    case UPDATE_AMOUNT:
      return {
        ...state,
        amount: action.payload
      }
    case UPDATE_COLUMNS:
      return {
        ...state,
        clicks: action.payload
      }
    case UPDATE_LEAGUES:
      return {
        ...state,
        leagues: action.payload
      }
    case UPDATE_LOADED:
      return {
        ...state,
        loaded: action.payload
      }
    case UPDATE_CLICKS_E_D:
      return {
        ...state,
        clicksED: action.payload
      }
    case UPDATE_PARTNER:
      return {
        ...state,
        partner: action.payload
      }
    case UPDATE_PENDING_ACTION_GROUP:
      return {
        ...state,
        pendingActionGroup: action.payload
      }
    default:
      return state
  }
}
