import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Button,
} from '@material-ui/core';
import { logout } from 'store/modules/auth/actions';
import expiredImage from 'assets/images/illustrations/expired.png';
import useRouter from 'utils/useRouter';

const SessionExpiredPage = () => {

  const { history } = useRouter();
  const dispatch = useDispatch();

  const handleAgainLogin = () => {
    dispatch(logout());
    history.push('/auth/login');
  }

  return (
    <Fragment>
      <div className="app-wrapper bg-white">
        <div className="app-main">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <Grid
                        item
                        lg={6}
                        md={9}
                        className="px-4 px-lg-0 mx-auto text-center text-black">
                        <img
                          src={expiredImage}
                          className="w-50 mx-auto d-block my-5 img-fluid"
                          alt="..."
                        />

                        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                          Your session Expired
                        </h3>
                        <p className="mb-4">
                          Your session expired in every 24 hours.
                          If you want to continue session, please login in again.
                        </p>
                        <Button
                          disableElevation
                          variant="contained"
                          className="ml-3"
                          size="large"
                          color="primary"
                          onClick={handleAgainLogin}
                        >
                          <span className="btn-wrapper--label">Login Again</span>
                        </Button>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SessionExpiredPage;
