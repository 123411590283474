import {
    AuthPost,
    AuthGet,
    AuthDelete,
    AuthPut
} from "./request.service";

export const getBets = (pageNumber, group) => {
  return AuthGet(`/bets?pageNumber=${pageNumber}&group=${group}`);
}

export const getAdvancedBets = (
    pageNumber,
    group,
    startDate,
    endDate,
    bookIds,
    shareTypeIds,
    betCategories,
    betTypes,
    leagues,
    betTime,
    tippers,
    tippersAgainst,
    betIds,
    teamFor,
    teamAgainst,
    betStatus,
    betSide,
    sortBy
  ) => {
    return AuthGet(`/bets?pageNumber=${pageNumber}&group=${group}&startDate=${startDate}&endDate=${endDate}&bookIds=${bookIds}&shareTypeIds=${shareTypeIds}&betCategories=${betCategories}&betTypes=${betTypes}&leagues=${leagues}&betTimes=${betTime}&tippersFor=${tippers}&tippersAgainst=${tippersAgainst}&betIds=${betIds}&teamFor=${teamFor}&teamAgainst=${teamAgainst}&betStatus=${betStatus}&betSide=${betSide}&sortBy=${sortBy}`);
}


export const addBet = (data) => {
  return AuthPost(`/bets`, data);
}

export const updateBet = (data) => {
  return AuthPut(`/bets`, data);
}

export const deleteBet = (user, id) => {
  return AuthDelete(`/bets?user=${user}&id=${id}`);
}

export const changeState = (data) => {
  return AuthPut(`/bets/status`, data);
}

export const getCategory = () => {
  return AuthGet(`/bets/search?feature=bet_category`);
}

export const getBetTime = () => {
  return AuthGet(`/bets/search?feature=bet_time`);
}

export const getBetType = () => {
  return AuthGet(`/bets/search?feature=bet_type`);
}

export const getLeague = () => {
  return AuthGet(`/bets/search?feature=league_name`);
}

export const getTeams = () => {
  return AuthGet(`/bets/search?feature=team`);
}

export const getShareType = () => {
  return AuthGet(`/bets/search?feature=share_type`);
}

export const getExportDB = (params) => {
  return AuthGet(`/db_export${params}`);
}

export const getBetForm = () => {
  return AuthGet(`/bet_form`);
}

export const eventEditDate = (data) => {
  return AuthPost(`/event_edit_date`, data);
}