/*eslint-disable*/
import {
  AuthGet,
  AuthGetMultiple
} from './request.service';

export const getLeagues = () => {
  return AuthGet(`/b365/leagues`);
};

export const getGrader = () => {
  return AuthGet(`/b365/grader`);
};

export const GetMultiLeagueEvents = (sportId, leagueId, date) => {
  const requestUrlTemplates = leagueId.map(
    id => `/b365/events?sportId=${sportId}&&leagueId=${id}&&date=${date}`
  );

  return AuthGetMultiple(requestUrlTemplates);
};

export const getEvents = (sportId, leagueId, date) => {
  if (leagueId !== null) {
    return AuthGet(
      `/b365/events?sportId=${sportId}&&leagueId=${leagueId}&&date=${date}`
    );
  } else {
    return AuthGet(`/b365/events?sportId=${sportId}&&date=${date}`);
  }
};

export const getMarkets = (eventId, sportId) => {
  return AuthGet(`/b365/markets?eventId=${eventId}&sportId=${sportId}`);
};

export const getMarketsOnSearch = searchValue => {
  return AuthGet(`/b365/markets/search?query=${searchValue}`);
};
