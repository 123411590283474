import { SUCCESS_LOGIN, LOGOUT } from './types'
import { storageUserKey } from "utils/constants"
import { setToken, removeToken } from "services/token.service";

const initialState = {
  user: false,
  accessToken: false,
  group: 'Super',
  isAdmin: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_LOGIN:
      localStorage.setItem(storageUserKey, JSON.stringify(action.payload));
      setToken(action.payload.accessToken.jwtToken);
      return {
        ...state,
        ...action.payload
      }
    case LOGOUT:
      localStorage.removeItem(storageUserKey);
      removeToken();
      localStorage.clear();
      return {
        ...initialState
      }
    default:
      return state
  }
}
