import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';

import { connect } from 'react-redux';

import projectLogo from '../../assets/images/react.svg';

const HeaderLogo = props => {
  const { sidebarToggle, sidebarHover } = props;

  const preferences = useSelector(state => state.preferences);

  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}>
        <Box
          className="header-logo-wrapper"
          title="">
          <Link to="/" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn">
              <img
                className="app-header-logo-img"
                alt=""
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">{preferences.company==='runItUp'?'Run it up':preferences.company === 'maxEmpire' ? 'Max Empire' : 'The Betting Syndicate'}</Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
