import { createMuiTheme } from '@material-ui/core';
import typography from './typography';

/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
import vars from '!!sass-vars-to-js-loader!../assets/core/_variables-mui.scss';

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: vars.primary
    },
    secondary: {
      main: vars.secondary
    },
    error: {
      main: vars.red
    },
    success: {
      main: vars.green
    },
    warning: {
      main: vars.orange
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)'
    },
    action: {
      active: '#3D4977',
      hover: '#3D4977',
      selected: '#3D4977',
      focus: '#3D4977'
    },
    contrastThreshold: 3,
    tonalOffset: 0.1
  },
  shape: {
    borderRadius: '0.5rem'
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px'
      },
      arrow: {
        color: vars.second
      }
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: '#3D4977',
          color: 'white'
        },
        '&[aria-selected="true"]': {
          backgroundColor: '#3D4977',
          color: 'white'
        },
      },
    },
    MuiMenuItem: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: '#3D4977',
          color: 'white'
        },
        '&:hover': {
          backgroundColor: '#3D4977',
          color: 'white'
        },
      },
    },
    MuiListItem: {
      root: {
        "&$selected, &$selected:hover": {
          backgroundColor: "#3D4977",
          color: 'white',
          "button": {
            color: 'white',
          }
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "#71778f",
          color: 'white'
        }
      }
    },    
    MuiChip: {
      root: {
        backgroundColor: '#3D4977',
        color: 'white',
      },
      icon: {
        color: 'brown',
      },
      deleteIcon: {
        color: 'white',
        '&:hover': {
          color: '#C9CAC8',
      },
      },
    },
  },
  typography
});

export default MuiTheme;
