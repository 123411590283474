import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Sidebar, Header, ThemeConfigurator } from '../../layout-components-user';
import AuthVerify from 'components/AuthVerify';
import InitInfo from 'components/InitInfo';

const LeftSidebar = props => {
  const {
    children,
    sidebarToggle,
    sidebarFixed,
    footerFixed,
    contentBackground
  } = props;

  return (
    <AuthVerify>
      <InitInfo>
        <Fragment>
          <div className={clsx('app-wrapper', contentBackground)}>
            <Header />
            <div
              className={clsx('app-main', {
                'app-main-sidebar-static': !sidebarFixed
              })}>
              <Sidebar />
              <div
                className={clsx('app-content', {
                  'app-content-sidebar-collapsed': sidebarToggle,
                  'app-content-sidebar-fixed': sidebarFixed,
                  'app-content-footer-fixed': footerFixed
                })}>
                <div className="app-content--inner">
                  <div className="app-content--inner__wrapper">{children}</div>
                </div>
              </div>
              <ThemeConfigurator />
            </div>
          </div>
        </Fragment>
      </InitInfo>
    </AuthVerify>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,

  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps)(LeftSidebar);
