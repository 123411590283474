import PeopleOutline from '@material-ui/icons/PeopleOutline';
import Money from '@material-ui/icons/Money';
import AddBox from '@material-ui/icons/AddBox';
import AmpStories from '@material-ui/icons/AmpStories';
import TrendingUp from '@material-ui/icons/TrendingUp';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CompareIcon from '@material-ui/icons/Compare';
import CameraFrontIcon from '@material-ui/icons/CameraFront';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import DnsIcon from '@material-ui/icons/Dns';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import moment from 'moment-timezone';

const clicksStartDate = moment()
  .tz('America/Los_Angeles')
  .weekday(1)
  .format('YYYY-MM-DD');
const clicksEndDate = moment('1/1/2099').format('YYYY-MM-DD');

const returnStartDate = moment('1/1/1970').format('YYYY-MM-DD');
const returnEndDate = moment('1/1/2099').format('YYYY-MM-DD');

const completedBetStartDate = moment()
  .tz('America/Los_Angeles')
  .weekday(1)
  .format('YYYY-MM-DD');
const completedBetEndDate = moment('1/1/2099').format('YYYY-MM-DD');

const accountingOverviewStartDate = moment()
  .tz('America/Los_Angeles')
  .weekday(1)
  .format('YYYY-MM-DD');
const accountingOverviewEndDate = moment('1/1/2099').format('YYYY-MM-DD');

export default [
  {
    label: 'Pages',
    content: [
      {
        label: 'Dashboard',
        icon: DashboardIcon,
        description: '',
        to: '/user/dashboard'
      },
      {
        label: 'Reports',
        icon: TrendingUp,
        content: [
          {
            label: 'Accounting',
            icon: SupervisorAccountIcon,
            description: '',
            to: `/user/report/accounting?partnerId=0&bookId=0&currency=USD`
          },
          {
            label: 'Accounting Overview',
            icon: SupervisorAccountIcon,
            description: '',
            to: `/user/report/accountingoverview?view=overview&startDate=${accountingOverviewStartDate}&endDate=${accountingOverviewEndDate}`
          },
          {
            label: 'Completed Bets',
            icon: CheckCircleIcon,
            description: '',
            to: `/user/report/completedbets?pageNumber=1&view=overview&startDate=${completedBetStartDate}&endDate=${completedBetEndDate}`
          },
          {
            label: 'Pending Action',
            icon: CompareArrowsIcon,
            description: '',
            to: '/user/report/pendingaction?partnerId=1'
          },
          {
            label: 'Tipper',
            icon: CameraFrontIcon,
            description: '',
            to: '/user/report/tipper?tipper=JS'
          },
          {
            label: 'P&L',
            icon: DnsIcon,
            description: '',
            to: '/user/report/pl?y=2023&q=1'
          },
          {
            label: 'Analyze',
            icon: EqualizerIcon,
            description: '',
            to: '/user/report/analyze'
          }
        ]
      },
      {
        label: 'Logging',
        icon: FormatIndentIncreaseIcon,
        content: [
          {
            label: 'Clicks',
            icon: AddBox,
            description: '',
            to: `/user/bets?pageNumber=1&group=all&startDate=${clicksStartDate}&endDate=${clicksEndDate}&sortBy=id(desc)`
          },
          {
            label: 'Closing Lines',
            icon: AmpStories,
            description: '',
            to: `/user/closinglines?pageNumber=1&sports=&betTypes=&startDate=${returnStartDate}&endDate=${returnEndDate}&missingOnly=1`
          },
          {
            label: 'Returns',
            icon: AssignmentReturnIcon,
            description: '',
            to: `/user/returns?pageNumber=1&startDate=${returnStartDate}&endDate=${returnEndDate}&partners=`
          },
          {
            label: 'Books',
            icon: ImportContactsIcon,
            description: '',
            to: '/user/books'
          },
          {
            label: 'Partners',
            icon: CompareIcon,
            description: '',
            to: '/user/partners'
          },
          {
            label: 'Share Type',
            icon: PeopleOutline,
            description: '',
            to: '/user/sharetype'
          },
          {
            label: 'Tippers',
            icon: Money,
            description: '',
            to: '/user/tippers'
          },
          {
            label: 'Transactions',
            icon: MonetizationOnIcon,
            description: '',
            to: '/user/transactions'
          },
          {
            label: 'Equities',
            icon: FormatPaintIcon,
            description: '',
            to: '/user/equities'
          }
        ]
      }
    ]
  }
];
