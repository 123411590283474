import { combineReducers } from 'redux';

import auth from './auth/reducer';
import betTabs from './betTabs/reducer';
import search from './search/reducer';
import users from './users/reducer';
import preferences from './preferences/reducer';
import ThemeOptions from './ThemeOptions/reducer';

export default () => combineReducers({
    ThemeOptions,
    auth,
    betTabs,
    search,
    users,
    preferences
})
