import TrendingUp from '@material-ui/icons/TrendingUp';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import moment from 'moment-timezone';

export default [
  {
    label: 'Pages',
    content: [
      {
        label: "Reports",
        icon: TrendingUp,
        content: [
          {
            label: "Accounting",
            icon: SupervisorAccountIcon,
            description: "",
            to: `/user/report/accounting?partnerId=0&bookId=0&currency=USD`,
          },
          {
            label: "Pending Action",
            icon: CompareArrowsIcon,
            description: "",
            to: "/user/report/pendingaction?partnerId=1",
          },
        ]
      },
    ],
  },
];
