import {
  SET_LEAGUE,
  SET_PARENTS,
  SET_SHARE_TYPE,
  SET_TIPPERS
} from './types'

const initialState = {
  league: [],
  parents: [],
  shareTypes: [],
  tippers: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LEAGUE:
      return {
        ...state,
        league: action.payload
      }
    case SET_PARENTS:
      return {
        ...state,
        parents: action.payload
      }
    case SET_SHARE_TYPE:
      return {
        ...state,
        shareTypes: action.payload
      }
    case SET_TIPPERS:
      return {
        ...state,
        tippers: action.payload
      }

    default:
      return state
  }
}
