export const storageTokenKey = "ACCESS_TOKEN"
export const storageUserKey = "CURRENT_USER"
export const storageClicksTableOrder = 'CLICKS_TABLE'
export const storageClicksTableSelectedRows = 'CLICKS_SELECTED_ROWS'

export const addInitData = {
  id: null,
  league: null,
  sport_id: null,
  league_id: null,
  event: null,
  home: '',
  away: '',
  event_id: null,
  date: null,
  bet: '',
  bet_type: null,
  bet_category: null,
  bet_side: null,
  handicap: 0,
  line: {
    value: '',
    lineUSD: true,
  },
  amount: {
    value: '',
    risk: true,
  },
  currency: '',
  bet_time: '',
  book_name: null,
  book_id: null,
  share_type: null,
  tips_for: [],
  tips_against: [],
}