/* eslint-disable no-use-before-define */
import React, { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/modules/auth/actions';
import { getToken } from "services/token.service";
import useRouter from 'utils/useRouter';

const AuthVerify = ({ children }) => {

  let timer;

  const { history } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    resetTimer();
    handleLogoutTimer();
  }, []);

  const handleLogoutTimer = () => {
    timer = setInterval(() => {
      logoutAction();
    }, 3000); // 10000ms = 10secs. You can change the time.
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const logoutAction = () => {
    // console.log('check log')
    const token = getToken();

    if (token === null) {
      resetTimer();
      window.location = '/errors/session-expired';
    }
  };

  return children;
};

export default AuthVerify;
