import {
  UPDATE_ODDS,
  UPDATE_COLUMNS,
  UPDATE_AMOUNT,
  UPDATE_LEAGUES,
  UPDATE_LOADED,
  UPDATE_PARTNER,
  UPDATE_CLICKS_E_D,
  UPDATE_PENDING_ACTION_GROUP
} from './types'

export const updateOdds = (data) => dispatch => {
  dispatch({
    type: UPDATE_ODDS,
    payload: data
  })
}

export const updateAmount = (data) => dispatch => {
  dispatch({
    type: UPDATE_AMOUNT,
    payload: data
  })
}

export const updateClickColumns = (data) => dispatch => {
  dispatch({
    type: UPDATE_COLUMNS,
    payload: data
  })
}

export const updateFavLeagues = (data) => dispatch => {
  dispatch({
    type: UPDATE_LEAGUES,
    payload: data
  })
}

export const updateLoaded = (data) => dispatch => {
  dispatch({
    type: UPDATE_LOADED,
    payload: data
  })
}

export const updateClicksEdiDup = (data) => dispatch => {
  dispatch({
    type: UPDATE_CLICKS_E_D,
    payload: data
  })
}

export const updatePartner = (data) => dispatch => {
  dispatch({
    type: UPDATE_PARTNER,
    payload: data
  })
}

export const updatePendingActionGroup = (data) => dispatch => {
  dispatch({
    type: UPDATE_PENDING_ACTION_GROUP,
    payload: data
  })
}