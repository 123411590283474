import React from 'react';
import './ErrorFallback.scss';
import ErrorImg from 'assets/images/wrong.png';

const ErrorFallback = ({ error, resetErrorBoundary }) => {

  return (
    <div role="alert" className='react-error'>
      {/* <img src={ErrorImg} alt="error-img" /> */}
      {/* <div>
        <ErrorIcon color='warning' style={{fontSize:'100px'}}/>
      </div> */}
      <div className='react-error--main'>
        <p>Something went wrong.</p>
      </div>
      {/* <div className='react-error--message'>
        <p>error</p>
      </div>
      <div className='react-error--contact'>
        <p>Please contact support of this continues</p>
      </div>
      <div className='react-error--btn'>
        <button onClick={resetErrorBoundary}>Continue</button>
      </div> */}
    </div>
  );
}

export default ErrorFallback;