import {
    AuthPost,
    AuthGet
} from "./request.service";

export const getUserPreference = (userName) => {
    return AuthGet(`/users/preferences?user=${userName}`);
}

export const updateUserPreference = (data) => {
    return AuthPost(`/users/preferences`, data);
}