import React, { Fragment, useState } from 'react';

import { IconButton, Box, Tooltip, Button } from '@material-ui/core';

import { Bell, Activity, Briefcase, Calendar } from 'react-feather';

import * as Bet365Service from 'services/b365.service';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  containedPurple: {
    color: 'white',
    backgroundColor: '#008000',
    "&:hover": {
      backgroundColor: '#43C059',
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: '#43C059'
      }
    }
  },
}));

export default function SidebarFooter() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [ nValue, setNValue] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onGradeBets = () => {
    Bet365Service.getGrader().then((result)=>{
      const _nValue = result.data.n_completed;
      setOpen(true);
      setNValue(_nValue);
    });
  }

  return (
    <Fragment>
      <Box className="app-sidebar-footer-wrapper">
        <Button variant="contained" className={classes.containedPurple} fullWidth onClick={()=>onGradeBets()}>Grade Bets</Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {nValue} clicks graded.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
    </Fragment>
  );
}
