import { SUCCESS_LOGIN, LOGOUT } from './types'

export const login = (credential) => dispatch => {
    dispatch({
      type: SUCCESS_LOGIN,
      payload: credential
    });
}

export const logout = () => dispatch => {
    dispatch({
      type: LOGOUT
    })
}
