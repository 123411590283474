import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Grid,
  TextField,
  Card,
  CardContent,
  FormControlLabel
} from '@material-ui/core';
import { useErrorHandler } from 'react-error-boundary';
import moment from 'moment-timezone';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Checkbox, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MultiSelect from 'components/MultiSelect';
import * as Bet365Service from 'services/b365.service';
import * as BetService from 'services/bet.service';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: '10px'
  }
}));

export default function AdvancedSearch(props) {
  const classes = useStyles();
  const { state, onAdvancedSearch } = props;
  const handleError = useErrorHandler();

  const [formState, setFormState] = useState({
    values: {
      pageNumber: 1,
      sports: [],
      betTypes: [],
      startDate: moment()
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD'),
      missingOnly: true
    }
  });

  const [sportOptions, setSportOptions] = useState([]);
  const [betTypeOptions, setBetTypeOptions] = useState([]);
  const testElementRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      Bet365Service.getLeagues().then(response => {
        if (response.status === 200) {
          try {
            const sports = response.data.map(item => ({
              label: item.name,
              value: item.name
            }));

            setSportOptions(sports);
          } catch (err) {
            handleError('Bet365Service response error');
          }
        } else {
          handleError('Bet365Service api error');
        }
      });

      BetService.getBetType().then(result => {
        if (result.status === 200) {
          try {
            const types = result.data.map(item => ({
              label: item.bet_type,
              value: item.bet_type
            }));
            setBetTypeOptions(types);
          } catch (err) {
            handleError('BetService.getBetType response error');
          }
        } else {
          handleError('BetService.getBetType api error');
        }
      });
    }, 2000);
  }, []);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      testElementRef.current.focus();
    }, 3000);
    const timer2 = setTimeout(() => {
      testElementRef.current.focus();
    }, 4000);
    const timer3 = setTimeout(() => {
      testElementRef.current.focus();
    }, 5000);
  }, []);

  useEffect(() => {
    setFormState({
      values: {
        pageNumber: state.values.pageNumber,
        sports: state.values.sports,
        betTypes: state.values.betTypes,
        startDate: state.values.startDate,
        endDate: state.values.endDate,
        missingOnly: state.values.missingOnly
      }
    });
  }, [state]);

  const selectValueChange = (name, value) => {
    if (name === 'startDate') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          startDate: value,
          endDate: value
        }
      }));
    }

    if (name === 'endDate') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          endDate: value
        }
      }));
    }

    if (name === 'pageNumber') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          pageNumber: value
        }
      }));
    }
  };

  const missingChange = event => {
    event.persist(); // Prevents the event from being reused/released
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        missingOnly: event.target.checked
      }
    }));
  };

  const multiSelectChange = (name, value) => {
    console.log('value', value);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value.map(a => a.value)
      }
    }));
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={5}>
          <Grid item xs={1} lg={1}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  onChange={missingChange}
                  checked={formState.values.missingOnly}
                />
              }
              label="Missing Only"
              labelPlacement="end"
              tabIndex={1}
            />
          </Grid>
          <Grid item xs={3} lg={3}>
            <MultiSelect
              items={sportOptions}
              label="Sports"
              selectAllLabel="Select all"
              selectedValues={formState.values.sports.map(item => ({
                value: item,
                label: item
              }))}
              onChange={v => multiSelectChange('sports', v)}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <MultiSelect
              items={betTypeOptions}
              label="Bet Types"
              selectAllLabel="Select all"
              selectedValues={formState.values.betTypes.map(item => ({
                value: item,
                label: item
              }))}
              onChange={v => multiSelectChange('betTypes', v)}
            />
          </Grid>
          <Grid item xs={2} lg={2}>
            <TextField
              label="Start Date"
              type="date"
              name="startDate"
              variant="outlined"
              size="small"
              inputRef={testElementRef}
              value={formState.values.startDate}
              fullWidth
              onChange={event =>
                selectValueChange('startDate', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={2} lg={2}>
            <TextField
              label="End Date"
              type="date"
              name="endDate"
              variant="outlined"
              size="small"
              value={formState.values.endDate}
              fullWidth
              onChange={event =>
                selectValueChange('endDate', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} lg={12} className="d-flex justify-content-end">
            <Button
              size="small"
              color="primary"
              className="justify-content-end"
              onClick={() => onAdvancedSearch(formState)}>
              Search
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
