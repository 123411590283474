import {
  AuthPost,
  AuthGet,
  AuthPut,
  AuthDelete
} from "./request.service";

export const getPartners = () => {
  return AuthGet(`/partners`);
}

export const addPartner = (data) => {
  return AuthPost(`/partners`, data);
}

export const updatePartner = (data) => {
  return AuthPut(`/partners`, data);
}

export const deletePartner = (user, id) => {
  return AuthDelete(`/partners?user=${user}&id=${id}`);
}

export const enableLogin = (partner) => {
  return AuthGet(`/partners/login?partner=${partner}&action=enable`);
}

export const disableLogin = (partner) => {
  return AuthGet(`/partners/login?partner=${partner}&action=disable`);
}

export const resetPassword = (partner) => {
  return AuthGet(`/partners/login?partner=${partner}&action=reset`);
}
