import {
  AuthGet,
  AuthPost,
  AuthDelete,
  AuthPut
} from "./request.service";

export const getTippers = () => {
  return AuthGet(`/tippers`);
}

export const addTipper = (data) => {
  return AuthPost(`/tippers`, data);
}

export const changeTipper = data => {
  return AuthPut(`/bets/tipper`, data);
};

export const deleteTipper = (user, id) => {
  return AuthDelete(`/tippers?user=${user}&id=${id}`);
}

