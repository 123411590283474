import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';
import { useTheme } from '@material-ui/core';

import { ClimbingBoxLoader } from 'react-spinners';

import MuiTheme from './theme';
import MuiTheme2 from './theme2';

// Layout Blueprints

import {
  LeftSidebar as UserLeftSidebar,
  MinimalLayout as UserMinimalLayout
} from './layout-blueprints-user';

import AuthVerify from 'components/AuthVerify';
import InitInfo from 'components/InitInfo';

// Example Pages
import PagesError404 from './example-pages/PagesError404';
import PagesError500 from './example-pages/PagesError500';
import PagesError505 from './example-pages/PagesError505';
import SessionExpired from 'pages/SessionExpired';
import ClosingLines from 'pages/ClosingLines';

// User Pages
const UserPagesLogin = lazy(() => import('pages/PagesLogin'));
const RecoverPassword = lazy(() => import('pages/RecoverPassword'));
const Dashboard = lazy(() => import('pages/Dashboard/'));
const ShareType = lazy(() => import('pages/ShareType/'));
const Tippers = lazy(() => import('pages/Tippers/'));
const Partners = lazy(() => import('pages/Partners/'));
const Freerolls = lazy(() => import('pages/Freerolls/'));
const Books = lazy(() => import('pages/Books/'));
const Bets = lazy(() => import('pages/Bets/'));
const Accounting = lazy(() => import('pages/Reports/Accounting'));
const AccountingOverview = lazy(() =>
  import('pages/Reports/AccountingOverview')
);
const PendingAction = lazy(() => import('pages/Reports/PendingAction'));
const CompletedBets = lazy(() => import('pages/Reports/CompletedBets'));
const ReportTipper = lazy(() => import('pages/Reports/Tippers'));
const PLPage = lazy(() => import('pages/Reports/PL'));
const Analyze = lazy(() => import('pages/Reports/Analyze'));
const Returns = lazy(() => import('pages/Returns'));
const Transactions = lazy(() => import('pages/Transactions'));
const Preferences = lazy(() => import('pages/Preferences'));

const Routes = () => {
  const preferences = useSelector(state => state.preferences);
  const auth = useSelector(state => state.auth);

  const location = useLocation();
  const theme = useTheme();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    const theme = useTheme();
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader
              color={theme.palette.primary.main}
              loading={true}
            />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while load
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <ThemeProvider
      theme={preferences.company === 'runItUp' ? MuiTheme : MuiTheme2}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {preferences.company === 'runItUp'
            ? 'Run it up'
            : preferences.company === 'maxEmpire'
            ? 'Max Empire'
            : 'The Betting Syndicate'}
        </title>
        <link rel="canonical" href="https://www.runituplab.com/" />
      </Helmet>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/user/dashboard" />
            <Route
              path={[
                '/auth/login',
                '/auth/recoverpassword',
                '/errors/error-401',
                '/errors/error-404',
                '/errors/error-500',
                '/errors/session-expired'
              ]}>
              <UserMinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/auth/login" component={UserPagesLogin} />
                    <Route
                      path="/auth/recoverpassword"
                      component={RecoverPassword}
                    />
                    <Route path="/errors/error-401" component={PagesError404} />
                    <Route path="/errors/error-404" component={PagesError500} />
                    <Route path="/errors/error-500" component={PagesError505} />
                    <Route
                      path="/errors/session-expired"
                      component={SessionExpired}
                    />
                  </motion.div>
                </Switch>
              </UserMinimalLayout>
            </Route>
            {auth.group === 'Super' && (
              <Route
                path={[
                  '/user/sharetype',
                  '/user/tippers',
                  '/user/books',
                  '/user/bets',
                  '/user/closinglines',
                  '/user/report/accounting',
                  '/user/report/accountingoverview',
                  '/user/report/pendingaction',
                  '/user/report/completedbets',
                  '/user/report/tipper',
                  '/user/report/pl',
                  '/user/report/analyze',
                  '/user/equities',
                  '/user/dashboard',
                  '/user/returns',
                  '/user/preferences',
                  '/user/partners',
                  '/user/transactions'
                ]}>
                <UserLeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <Route path="/user/sharetype" component={ShareType} />
                    <Route path="/user/tippers" component={Tippers} />
                    <Route path="/user/books" component={Books} />
                    <Route path="/user/bets" component={Bets} />
                    <Route path="/user/closinglines" component={ClosingLines} />
                    <Route
                      path="/user/report/accounting"
                      component={Accounting}
                    />
                    <Route
                      path="/user/report/accountingoverview"
                      component={AccountingOverview}
                    />
                    <Route
                      path="/user/report/pendingaction"
                      component={PendingAction}
                    />
                    <Route
                      path="/user/report/completedbets"
                      component={CompletedBets}
                    />
                    <Route
                      path="/user/report/tipper"
                      component={ReportTipper}
                    />
                    <Route path="/user/report/pl" component={PLPage} />
                    <Route path="/user/report/analyze" component={Analyze} />
                    <Route path="/user/equities" component={Freerolls} />
                    <Route path="/user/partners" component={Partners} />
                    <Route path="/user/dashboard" component={Dashboard} />
                    <Route path="/user/returns" component={Returns} />
                    <Route path="/user/preferences" component={Preferences} />
                    <Route path="/user/transactions" component={Transactions} />
                  </Switch>
                </UserLeftSidebar>
              </Route>
            )}
            {auth.group === 'Limit' && (
              <Route
                path={[
                  '/user/report/accounting',
                  '/user/report/pendingaction',
                  '/user/dashboard'
                ]}>
                <UserLeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <Route
                      path="/user/report/accounting"
                      component={Accounting}
                    />
                    <Route
                      path="/user/report/pendingaction"
                      component={PendingAction}
                    />
                    <Route path="/user/dashboard" component={Dashboard} />
                  </Switch>
                </UserLeftSidebar>
              </Route>
            )}
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
