import {
  SET_LEAGUE,
  SET_PARENTS,
  SET_SHARE_TYPE,
  SET_TIPPERS
} from './types'

export const updateShareType = (shareType) => dispatch => {
  dispatch({
    type: SET_SHARE_TYPE,
    payload: shareType
  });
}

export const updateLeague = (league) => dispatch => {
  dispatch({
    type: SET_LEAGUE,
    payload: league
  });
}

export const updateParents = (parent) => dispatch => {
  dispatch({
    type: SET_PARENTS,
    payload: parent
  });
}

export const updateTippers = (tipper) => dispatch => {
  dispatch({
    type: SET_TIPPERS,
    payload: tipper
  });
}