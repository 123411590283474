import {
  SET_TABS,
  SET_DATA,
  SAVE_STATE
} from './types';
import { addInitData } from 'utils/constants';

const initialState = {
  tabs: [
    {
      title: 'Tab1',
      active: true,
      id: 1,
      data: addInitData //addInitData for clicks new bet
    }
  ],
  tabActionChange: true,
  saveTabState: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TABS:
      return {
        ...state,
        tabs: action.payload,
        tabActionChange: !state.tabActionChange
      }
    case SET_DATA:
      return {
        ...state,
        tabs: action.payload,
      }
    case SAVE_STATE:
      return {
        ...state,
        saveTabState: action.payload,
      }
    default:
      return state
  }
}
